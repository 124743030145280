.custom-date-range-picker {
  position: relative;
}

.custom-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.year-display {
  margin-top: 8px;
  color: #00FFFF;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.year-display .dropdown-arrow {
  margin-left: 5px;
}

.date-range-wrapper {
  position: absolute;
  z-index: 1000;
  top: 50px;
  right: 0px;
}

.bi-chevron-down {
  fill: #00FFFF;
}

.rdrInputRanges {
  display: none;
}

/* Custom Colors */

.custom-date-range {
  background-color: #444467 !important; /* Ensure consistent background */
  color: white !important;
}
/* Target the date display inputs */
.custom-date-range .rdrDateDisplayItem input {
  background-color: #444467 !important; /* Set background to match the theme */
  color: white !important;              /* Set text color to white */
  border: 1px solid white !important; /* Border color to match background */
}

.custom-date-range .rdrDateDisplayWrapper {
  background-color: #444467 !important; /* Ensure the wrapper matches the background */
}

.custom-date-range .rdrDateDisplayItem {
  background-color: #444467 !important; /* Background of the individual date range fields */
}


.custom-date-range .rdrMonthAndYearPickers select,
.custom-date-range .rdrMonthPicker select,
.custom-date-range .rdrYearPicker select {
  background-color: #444467 !important; /* Dropdown background */
  color: white !important;              /* Dropdown text color */
  border-color: #444467 !important;
}

.custom-date-range .rdrDefinedRangesWrapper {
  background-color: #444467 !important; /* Background for predefined ranges */
  color: white !important;              /* Text color for predefined ranges */
}

.custom-date-range .rdrDateDisplayItem input {
  background-color: #444467 !important;
  color: white !important;
}

.custom-date-range .rdrCalendarWrapper {
  background-color: #444467 !important;
}

.custom-date-range .rdrDayNumber span,
.custom-date-range .rdrMonthAndYearPickers select,
.custom-date-range .rdrYearPicker select {
  color: white !important;
}

.custom-date-range .rdrSelected,
.custom-date-range .rdrSelectedStart,
.custom-date-range .rdrSelectedEnd {
  background-color: #444467 !important;
}

.custom-date-range .rdrNextPrevButton i {
  color: white !important; /* Ensure arrows are white */
}

/* Style the arrow buttons */
.custom-date-range .rdrNextPrevButton {
  background-color: #444467 !important;  /* Match the rest of the theme */
  color: white !important;
}

/* Style the border and hover effect for the date range picker controls */
.custom-date-range .rdrMonthAndYearPickers .rdrPprevButton, 
.custom-date-range .rdrMonthAndYearPickers .rdrNextButton {
  background-color: #444467 !important; /* Match background */
  color: white !important;
  border-color: #444467 !important;
}

.custom-date-range .rdrDefinedRangesWrapper .rdrStaticRange {
  background-color: #444467 !important;
  color: white !important;
}

.custom-date-range .rdrDefinedRangesWrapper .rdrStaticRange:hover {
  background-color: #3C3C58 !important; /* Slight hover effect */
  color: rgb(61, 145, 255) !important;
}
